const en = {
    title: "Santa Teresa Villa",
    description: "Step into a world where modern luxury meets historic charm with this stunning villa in the heart of Santa Teresa, Rio de Janeiro...",
    featuresTitle: "Features:",
    features: [
        "Prime location in the historic and artistic Santa Teresa neighborhood",
        "Immediate access to the charming Bonde de Santa Teresa tram",
        "Just 5 minutes' walk to the stunning Escadaria Selarón",
        "Luxurious main building with 4 en-suite rooms, including 2 with stunning views",
        "Separate guest kitchen and 6 additional suites in the annex with a rooftop terrace offering breathtaking views and beautiful sunrises",
        "Architectural symmetry with a path leading to the main entrance",
        "Perfectly suited for a guest house/pousada, with full permission for operation"
    ],
    price: "Price: R$ 2,400,000.00",
    home: 'Home',
    descriptionPage: 'Description',
    media: 'Media',
    contact: 'Contact',
    contactTitle: "Contact Us",
    contactMessage: "Please fill out the form below to get in touch with us.",
    nameLabel: "Name",
    emailLabel: "Email",
    messageLabel: "Message",
    namePlaceholder: "Your Name",
    emailPlaceholder: "Your Email",
    messagePlaceholder: "Your Message",
    pricesInOtherCurrencies: "Prices in other currencies:",
    sendButton: "Send",
    successMessage: "Your message has been sent successfully!",
    errorMessage: "There was an error sending your message. Please try again.",
    neighborhoodTitle: "Explore the Neighborhood",
    neighborhoodDescription: "Discover the vibrant community around our guesthouse. Learn about local attractions, dining, and entertainment options that make Santa Teresa an exciting place to live.",
    overviewTitle: "Overview",
    overviewDescription: "Discover the enchanting neighborhood of Santa Teresa, renowned for its bohemian atmosphere, rich cultural heritage, and historic charm. Nestled in the heart of Rio de Janeiro, this vibrant area is a haven for artists, musicians, and anyone looking to experience a unique blend of old-world charm and contemporary creativity.",
    historyTitle: "History",
    historyDescription: "Santa Teresa has a rich history that dates back to the early 20th century. Once a fashionable district for Rio’s elite, its narrow streets and historic houses reflect the grandeur of its past. Today, it stands as a cultural treasure, preserving its historical architecture while embracing modern artistic expression.",
    cultureTitle: "Culture",
    cultureDescription: "The cultural scene in Santa Teresa is a dynamic mix of traditional and contemporary influences. From its vibrant street art to its lively samba music, the neighborhood offers a cultural experience that celebrates both its heritage and its ongoing creative evolution. The local community actively fosters artistic and cultural activities, making it a cultural hotspot in Rio.",
    landmarksTitle: "Landmarks",
    landmarksDescription: "Santa Teresa is home to several notable landmarks, including the iconic Escadaria Selarón and the historic Arcos da Lapa. These landmarks not only offer a glimpse into the area’s history but also provide stunning backdrops for photos and cultural exploration. Each site contributes to the neighborhood's unique character and historical significance.",
    localCuisineTitle: "Local Cuisine",
    localCuisineDescription: "The culinary scene in Santa Teresa is as diverse as its culture. From traditional Brazilian fare to innovative gastronomic creations, the local eateries offer a delightful range of flavors. Explore charming cafes and restaurants that reflect the neighborhood’s rich culinary heritage and contemporary trends.",
    thingsToDoTitle: "Things to Do",
    thingsToDoDescription: "Santa Teresa offers a variety of activities for visitors. Whether you’re interested in exploring art galleries, enjoying live music, or simply strolling through its picturesque streets, there’s something for everyone. The neighborhood’s vibrant atmosphere and friendly locals make it an ideal place to spend a day discovering hidden gems.",
    artGalleriesTitle: "Art Galleries",
    artGalleriesDescription: "Art galleries in Santa Teresa showcase a range of local and international art. These galleries are often located in charming, historic buildings that add to the aesthetic experience. Visitors can enjoy exhibitions that feature everything from contemporary art to traditional crafts, reflecting the neighborhood’s artistic spirit.",
    historicBuildingsTitle: "Historic Buildings",
    historicBuildingsDescription: "The historic buildings in Santa Teresa are a testament to the neighborhood’s rich past. These well-preserved structures, with their unique architectural styles, provide a glimpse into the area’s history and its evolution over the decades. Walking through the streets of Santa Teresa feels like stepping back in time.",
    eventsFestivalsTitle: "Events and Festivals",
    eventsFestivalsDescription: "Santa Teresa is known for its vibrant events and festivals that celebrate everything from music and dance to local traditions and food. These events are a great way to experience the neighborhood’s lively spirit and cultural diversity, attracting both locals and visitors alike.",
    sambaGuimaraesTitle: "Samba do Guimarães",
    sambaGuimaraesDescription: "A lively venue offering authentic samba performances in an intimate setting. Known for its energetic atmosphere and traditional music, it’s a must-visit for anyone looking to experience Rio’s famous samba culture.",
    address: "Address",
    openingHours: "Opening Hours",
    tickets: "Tickets",
    sambaGuimaraesHours: "Monday to Saturday, 7 PM - 2 AM",
    sambaGuimaraesTickets: "R$ 30",
    cineCasalTitle: "Cine Casal",
    cineCasalDescription: "An eclectic cinema offering a diverse selection of films and cultural events. It’s a great spot to catch indie films and participate in film-related discussions.",
    telephone: "Telephone",
    malocaTitle: "Maloca",
    malocaDescription: "A cozy spot known for its healthy and organic dishes. Maloca provides a relaxing atmosphere where you can enjoy fresh, nutritious meals.",
    malocaHours: "Monday to Friday, 11 AM - 4 PM",
    cantinhoTitle: "Cantinho do Açaí",
    cantinhoDescription: "Famous for its delicious açaí bowls and smoothies. It’s a popular place for locals and visitors to enjoy refreshing, healthy snacks.",
    cantinhoHours: "Monday to Saturday, 10 AM - 6 PM",
    raizesTitle: "Raízes",
    raizesDescription: "A vibrant eatery offering traditional Brazilian dishes with a modern twist. Raízes is known for its flavorful cuisine and warm, inviting atmosphere.",
    raizesWebsite: "www.raizesrestaurant.com",
    explorerTitle: "Explorer",
    explorerDescription: "A refined dining experience featuring contemporary Brazilian cuisine. Known for its stylish setting and exceptional service, Explorer is a great choice for a special meal.",
    explorerHours: "Tuesday to Sunday, 12 PM - 11 PM",
    aprazivelTitle: "Aprazível",
    aprazivelDescription: "An upscale restaurant with stunning views and a menu focusing on Brazilian and international dishes. Aprazível offers a sophisticated dining experience in a beautiful setting.",
    cafeAltoTitle: "Café do Alto",
    cafeAltoDescription: "A charming café known for its delicious coffee and light meals. It’s a perfect spot for a relaxed brunch or an afternoon coffee.",
    cafeAltoHours: "Monday to Saturday, 8 AM - 6 PM",
    barMineiroTitle: "Bar Mineiro",
    barMineiroDescription: "A traditional bar offering a range of Brazilian snacks and drinks. Bar Mineiro is a great place to experience local flavors in a casual setting.",
    simplesmenteTitle: "Simplesmente",
    simplesmenteDescription: "A popular restaurant serving classic Brazilian dishes with a focus on simplicity and flavor. Simplesmente is known for its hearty meals and welcoming atmosphere.",
    ruinsParkTitle: "Parque das Ruínas",
    ruinsParkDescription: "A historic site featuring the remains of an old mansion turned into a cultural center. The park offers beautiful views of the city and hosts various cultural events.",
    laurindaTitle: "Laurinda",
    laurindaDescription: "A charming venue offering a mix of traditional Brazilian music and contemporary performances. Laurinda is known for its vibrant atmosphere and high-quality entertainment.",
    chacaraTitle: "Chácara do Céu",
    chacaraDescription: "A beautiful cultural center and museum showcasing art, history, and nature. Chácara do Céu offers a serene environment and engaging exhibits.",
    chacaraTip: "Check the website for current exhibitions and special events.",
    largoGuimaraesTitle: "Largo do Guimarães",
    largoGuimaraesDescription: "A lively square known for its street performances and local markets. Largo do Guimarães is a great place to soak in the local atmosphere and enjoy some street food.",
    gastronomicPointTitle: "Gastronomic Points",
    gastronomicPointDescription: "Explore the diverse culinary scene in Santa Teresa. From high-end restaurants to hidden gems, the neighborhood offers a wide range of dining options to suit every taste.",
    localTipsTitle: "Local Tips",
    localTipsDescription: "Get the most out of your visit to Santa Teresa with these local tips. Discover the best spots for dining, entertainment, and cultural experiences.",
    santaTeresaTramTitle: "Santa Teresa Tram",
    santaTeresaTramDescription: "The most charming tip is without a question to take the Santa Teresa Tram and pass the Arcos da Lapa enjoying the wonderful view of the ride, enjoy the location of the neighborhood and be dazzled by the sights of downtown of the city and to Guanabara Bay, Museums and the airport Santos Dumont.",
    tramMuseumTitle: "Tram Museum",
    tramMuseumDescription: "The space has photos, parts and models about the tram and even one old life-size donkey-pulled tram. The museum is located at the entrance of the Santa Teresa garage and cable car maintenance place.",
    tijucaNationalForestTitle: "Tijuca National Forest and Corcovado",
    tijucaNationalForestDescription: "The neighborhood has access to Tijuca National Park and to Corcovado, two places that also worth the visit, for sure.",
    downloadPdfTitle: "Download PDF",
    downloadPdfDescription: "For more detailed information and high-resolution images, download our comprehensive PDF guide.",
    noContent: "No content available.",
    error404: "Page not found.",
    backToHome: "Back to Home",
    viewMore: "View More",
    viewLess: "View Less",
    readMore: "Read More",
    readLess: "Read Less",
    culturalPlacesTitle: "Cultural Places",
    gastronomicPointsTitle: "Gastronomic Points",
    highlightsTitle: "Highlights",
    healthyOptionsTitle: "Healthy Options",
    refinedOptionsTitle: "Refined Options",
    traditionalRestaurantsTitle: "Traditional Restaurants",
    attractionsTitle: "Attractions",
    mapTitle: "Map"
};

export default en;
