import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import lightbox styles
import './MediaScreen.css'; // Ensure this imports the updated CSS

// Explicitly import all images
import photo097 from '../assets/images/album/photo097.jpg';
import photo098 from '../assets/images/album/photo098.jpg';
import photo099 from '../assets/images/album/photo099.jpg';
import photo100 from '../assets/images/album/photo100.jpg';
import photo101 from '../assets/images/album/photo101.jpg';
import photo102 from '../assets/images/album/photo102.jpg';
import photo103 from '../assets/images/album/photo103.jpg';
import photo104 from '../assets/images/album/photo104.jpg';
import photo105 from '../assets/images/album/photo105.jpg';
import photo106 from '../assets/images/album/photo106.jpg';
import photo107 from '../assets/images/album/photo107.jpg';
import photo108 from '../assets/images/album/photo108.jpg';
import photo109 from '../assets/images/album/photo109.jpg';
import photo110 from '../assets/images/album/photo110.jpg';
import photo111 from '../assets/images/album/photo111.jpg';
import photo112 from '../assets/images/album/photo112.jpg';
import photo113 from '../assets/images/album/photo113.jpg';
import photo114 from '../assets/images/album/photo114.jpg';
import photo115 from '../assets/images/album/photo115.jpg';
import photo116 from '../assets/images/album/photo116.jpg';
import photo117 from '../assets/images/album/photo117.jpg';
import photo118 from '../assets/images/album/photo118.jpg';
import photo119 from '../assets/images/album/photo119.jpg';
import photo120 from '../assets/images/album/photo120.jpg';
import photo121 from '../assets/images/album/photo121.jpg';
import photo122 from '../assets/images/album/photo122.jpg';
import photo123 from '../assets/images/album/photo123.jpg';
import photo124 from '../assets/images/album/photo124.jpg';
import photo125 from '../assets/images/album/photo125.jpg';
import photo126 from '../assets/images/album/photo126.jpg';
import photo127 from '../assets/images/album/photo127.jpg';
import photo128 from '../assets/images/album/photo128.jpg';
import photo129 from '../assets/images/album/photo129.jpg';
import photo130 from '../assets/images/album/photo130.jpg';
import photo131 from '../assets/images/album/photo131.jpg';
import photo132 from '../assets/images/album/photo132.jpg';
import photo133 from '../assets/images/album/photo133.jpg';
import photo134 from '../assets/images/album/photo134.jpg';
import photo135 from '../assets/images/album/photo135.jpg';
import photo136 from '../assets/images/album/photo136.jpg';
import photo137 from '../assets/images/album/photo137.jpg';
import photo138 from '../assets/images/album/photo138.jpg';
import photo139 from '../assets/images/album/photo139.jpg';
import photo140 from '../assets/images/album/photo140.jpg';
import photo141 from '../assets/images/album/photo141.jpg';
import photo142 from '../assets/images/album/photo142.jpg';
import photo143 from '../assets/images/album/photo143.jpg';
import photo144 from '../assets/images/album/photo144.jpg';
import photo145 from '../assets/images/album/photo145.jpg';
import photo146 from '../assets/images/album/photo146.jpg';
import photo147 from '../assets/images/album/photo147.jpg';
import photo148 from '../assets/images/album/photo148.jpg';
import photo149 from '../assets/images/album/photo149.jpg';
import photo150 from '../assets/images/album/photo150.jpg';
import photo151 from '../assets/images/album/photo151.jpg';
import photo152 from '../assets/images/album/photo152.jpg';
import photo153 from '../assets/images/album/photo153.jpg';
import photo154 from '../assets/images/album/photo154.jpg';
import photo155 from '../assets/images/album/photo155.jpg';
import photo156 from '../assets/images/album/photo156.jpg';
import photo157 from '../assets/images/album/photo157.jpg';

const imageUrls = [
  photo097,
  photo098,
  photo099,
  photo100,
  photo101,
  photo102,
  photo103,
  photo104,
  photo105,
  photo106,
  photo107,
  photo108,
  photo109,
  photo110,
  photo111,
  photo112,
  photo113,
  photo114,
  photo115,
  photo116,
  photo117,
  photo118,
  photo119,
  photo120,
  photo121,
  photo122,
  photo123,
  photo124,
  photo125,
  photo126,
  photo127,
  photo128,
  photo129,
  photo130,
  photo131,
  photo132,
  photo133,
  photo134,
  photo135,
  photo136,
  photo137,
  photo138,
  photo139,
  photo140,
  photo141,
  photo142,
  photo143,
  photo144,
  photo145,
  photo146,
  photo147,
  photo148,
  photo149,
  photo150,
  photo151,
  photo152,
  photo153,
  photo154,
  photo155,
  photo156,
  photo157,
];

const MediaScreen = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <div className="media-screen">
      <div className="media-content">
        <h1>Media Gallery</h1>
        <div className="image-gallery">
          {imageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Media ${index}`}
              className="gallery-image"
              onClick={() => openLightbox(index)}
            />
          ))}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={imageUrls[photoIndex]}
            nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
            prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imageUrls.length)
            }
          />
        )}
      </div>
    </div>
  );
};

export default MediaScreen;
