const nl = {
    title: "Santa Teresa Villa",
    description: "Stap in een wereld waar moderne luxe samenkomt met historisch charme met deze prachtige villa in het hart van Santa Teresa, Rio de Janeiro...",
    featuresTitle: "Kenmerken:",
    features: [
        "Prime locatie in de historische en artistieke wijk Santa Teresa",
        "Directe toegang tot de charmante Bonde de Santa Teresa tram",
        "Op slechts 5 minuten lopen van de prachtige Escadaria Selarón",
        "Luxueus hoofdgebouw met 4 suites, waaronder 2 met verbluffende uitzichten",
        "Apart gastenkeuken en 6 extra suites in het bijgebouw met een dakterras dat adembenemende uitzichten en prachtige zonsopkomsten biedt",
        "Architecturale symmetrie met een pad naar de hoofd-ingang",
        "Perfect geschikt als gastenhuis/pousada, met volledige vergunning voor exploitatie"
    ],
    price: "Prijs: R$ 2.400.000,00",
    home: 'Home',
    descriptionPage: 'Beschrijving',
    media: 'Media',
    contact: 'Contact',
    contactTitle: "Neem Contact Op",
    contactMessage: "Vul het onderstaande formulier in om contact met ons op te nemen.",
    nameLabel: "Naam",
    emailLabel: "E-mail",
    messageLabel: "Bericht",
    namePlaceholder: "Jouw Naam",
    emailPlaceholder: "Jouw E-mail",
    messagePlaceholder: "Jouw Bericht",
    pricesInOtherCurrencies: "Prijzen in andere valuta:",
    sendButton: "Verstuur",
    successMessage: "Je bericht is succesvol verzonden!",
    errorMessage: "Er is een fout opgetreden bij het verzenden van je bericht. Probeer het opnieuw.",
    neighborhoodTitle: "Verken de Buurt",
    neighborhoodDescription: "Ontdek de levendige gemeenschap rondom onze bed and breakfast. Leer over lokale attracties, eetgelegenheden en entertainmentopties die Santa Teresa tot een spannende plek maken om te wonen.",
    tram: "Santa Teresa Tram",
    tramDescription: "De beste tip is zonder twijfel om de Santa Teresa Tram te nemen en de Arcos da Lapa te passeren terwijl je geniet van het prachtige uitzicht van de rit. Geniet van de ligging van de wijk en laat je betoveren door de uitzichten op het centrum van de stad en de Guanabara Baai, musea en het vliegveld.",
    tramMuseum: "Bezoek het Tram Museum",
    tramMuseumDescription: "Het museum heeft foto's, onderdelen en modellen van de tram, en zelfs een oude levensgrote ezelgetrokken tram. Het museum bevindt zich bij de ingang van de Santa Teresa garage en kabelbaan onderhoudsplek.",
    tijucaForest: "Tijuca Nationaal Park en Corcovado",
    tijucaForestDescription: "De wijk heeft toegang tot het Tijuca Nationaal Park en de Corcovado, twee plaatsen die absoluut een bezoek waard zijn.",
    localTipsTitle: "Santa Teresa in Rio de Janeiro – Lokale Tips voor de Buurt",
    localTipsDescription: "Weet je niet wat je moet doen in Rio de Janeiro? Een van de belangrijkste attracties van Rio is degene waar niet veel over wordt gesproken, maar die naar mijn mening een van de meest charmante buurten is die ik ooit heb bezocht: Santa Teresa! Vlak naast het drukke stadscentrum ligt een heuvel die de rustige en artistieke wijk Santa Teresa in Rio de Janeiro herbergt – met een uitzicht op de stad dat je adem zal benemen. We vertellen je alle details!",
    sambaGuimaraesTitle: "Samba dos Guimarães",
    sambaGuimaraesDescription: "De Samba dos Guimarães is een traditie in de wijk Santa Teresa. Samba is de bekendste muziekstijl van Rio de Janeiro, en hoe kun je Rio bezoeken zonder een Samba-avond te ervaren? Je moet absoluut genieten van een Samba-avond, die nog specialer wordt in deze historische en charmante wijk.",
    sambaGuimaraesHours: "Zaterdagen van 19:30 tot 01:30.",
    sambaGuimaraesTickets: "Entreeprijs: Tussen R$20 en R$30.",
    cineCasalTitle: "Cine Casal Santa",
    cineCasalDescription: "Voor filmliefhebbers is Cine Casal Santa een bezoek waard. Het is een klein en gezellig plek. Wat bijzonder is, is dat alternatieve films worden vertoond, weg van de commerciële producties.",
    cineCasalTelephone: "+55 (21) 2222-0203",
    healthyOptionsTitle: "Gezonde Eetgelegenheden",
    malocaTitle: "Maloca Gastrobar",
    malocaDescription: "Een plek met een zeer aangename sfeer, en als je vegetariër bent, is dit zeker een gouden optie.",
    malocaHours: "Dinsdag tot donderdag van 12:00 tot 22:00; Vrijdag tot zondag van 12:00 tot middernacht.",
    malocaTelephone: "+55 (21) 3174-3597",
    cantinhoTitle: "Cantinho de Santa",
    cantinhoDescription: "Een ruimte voor gezonde voeding met opties voor vegetariërs en veganisten. De gebruikte ingrediënten zijn allemaal biologisch en de prijzen zijn zeer redelijk, wat de plek nog specialer maakt. Kom en geniet van het leuke balkon dat de plek biedt!",
    cantinhoHours: "Dinsdag tot donderdag van 10:30 tot 18:30; Vrijdag en zaterdag van 10:00 tot 22:00.",
    cantinhoTelephone: "+55 (21) 3497-5122",
    raizesTitle: "Raízes do Brasil",
    raizesDescription: "Als je naar Santa Teresa gaat, moet je deze plek bezoeken. De locatie is verbonden met de agro-ecologische beweging, met kleine producenten. Met een zeer rustieke inrichting en heerlijk eten. Ze bieden boerencafé, lunches (met self-service gerechten tegen zeer voordelige prijzen) en snacks in de middag aan. Ze hebben ook een boerderijmand (markt). Vaak is er ook live muziek!",
    raizesWebsite: "Bekijk de website voor openingstijden.",
    raizesTelephone: "+55 (21) 3081-4652",
    refinedOptionsTitle: "Verfijnde Eetgelegenheden",
    explorerTitle: "Explorer Bar",
    explorerDescription: "Een zeer gastvrije bar voor cocktails met heerlijke opties op het menu, zeker een bezoek waard.",
    explorerHours: "Dinsdag tot donderdag van 17:00 tot middernacht; Vrijdag van 17:00 tot 01:00; Zaterdag van 14:00 tot 01:00; Zondag van 14:00 tot middernacht.",
    explorerTelephone: "+55 (21) 3264-9665",
    aprazivelTitle: "Aprazível",
    aprazivelDescription: "Als je op zoek bent naar een meer verfijnde plek met indrukwekkende uitzichten en een gevarieerd menu met natuurlijke en tropische elementen, is dit de perfecte keuze. Met zelfgemaakte producten zoals chocolade, wijn, bier, koffie en cachaça, is het een uitstekende aanbeveling.",
    aprazivelHours: "Woensdag tot zaterdag van 12:00 tot 23:00; Zondag van 12:00 tot 18:00.",
    aprazivelTelephone: "+55 (21) 2508-9174",
    traditionalRestaurantsTitle: "Traditionele Restaurants",
    cafeAltoTitle: "Café do Alto",
    cafeAltoDescription: "Een goede restaurantoptie. Een echt unieke plek in Rio de Janeiro! We houden echt van dit café. De plek biedt traditionele keuken uit het Noordoosten van het land.",
    cafeAltoHours: "Maandag en dinsdag van 12:00 tot 21:00; Donderdag en vrijdag van 12:00 tot 21:00; Zaterdag en zondag van 09:00 tot 20:00.",
    cafeAltoTelephone: "+55 (21) 2507-3172, +55 (21) 9802-41666",
    barMineiroTitle: "Bar do Mineiro",
    barMineiroDescription: "Een zeer bekende plek die zowel door de lokale bevolking als toeristen wordt bezocht. Het is een eenvoudige bar, en mensen staan meestal terwijl ze bier drinken omdat er weinig tafels zijn. Informeler? De plek biedt ook een feijoada op zaterdag.",
    barMineiroHours: "Dinsdag tot zondag van 11:00 tot 23:00.",
    barMineiroTelephone: "+55 (21) 2221-9227",
    simplesmenteTitle: "Simplesmente",
    simplesmenteDescription: "Een van de favorieten van de wijk, met de eerder genoemde Bar Mineiro. Het heeft een redelijk menu en is meestal rustiger, ideaal voor snacks en echt frisse bier.",
    simplesmenteHours: "Dinsdag tot donderdag van 16:00 tot 03:30; Zaterdag van 13:00 tot 03:30 en zondag van 13:00 tot 02:00.",
    simplesmenteTelephone: "+55 (21) 2507-1365",
    ruinsParkTitle: "Parque das Ruínas",
    ruinsParkDescription: "Een van de belangrijkste attracties van de wijk is het museum Parque das Ruínas, een must-see. Dit was de woning van zangeres Laurinda dos Santos Lobo en er vonden veel feesten en evenementen plaats met beroemde kunstenaars uit de Carioca-samenleving, zoals Tarsila do Amaral, in de XIXe eeuw, en het ontving zelfs presidenten. In 1993 werd het gebouw uitgeroepen tot monument dat de structuur in ruïnes en zichtbare bakstenen behoudt. Van daaruit hebben we een geweldige en bevoorrechte 360º uitzicht op de Baai van Guanabara, het centrum van de stad, en een tip: de foto's die daar worden genomen zijn ongelooflijk. De locatie biedt altijd gratis programmering met exposities en theater, muziekoptredens en veel meer attracties. Je kunt ook even stoppen voor een koffie binnen en genieten van je koffie met uitzicht.",
    ruinsParkAddress: "Rua Murtinho Nobre, 169 – Santa Teresa",
    ruinsParkHours: "Van dinsdag tot zondag, van 8 tot 18 uur.",
    ruinsParkTickets: "Gratis toegang",
    laurindaCenterTitle: "Laurinda Santos Lobo Cultureel Centrum",
    laurindaCenterDescription: "Laurinda ontving nog een eerbetoon in dezelfde buurt, met een cultureel centrum dat haar naam draagt. Het Laurinda Santos Lobo Cultureel Centrum werd in 1979 opgericht door een groep zeer belangrijke mensen uit de regio. Het cultureel centrum bevindt zich in een groot roze huis, en Laurinda heeft daar nooit gewoond. In feite werd het grote huis in 1907 gebouwd door de Baronesa de Parina en was het de woning van belangrijke politici uit die tijd. Maar de eerbetoon aan Laurinda vond plaats in een tijd dat haar oude huis, tegenwoordig Parque das Ruínas, verlaten was. Bekend als “De Laurinda”, is het cultureel centrum een van de buurtplekken waar de herinnering aan Santa Teresa het meest wordt gerespecteerd. Er zijn exposities, dans- en muziekworkshops, fotoprojecties, theatervoorstellingen, kinderactiviteiten, pianorecitals en enkele buitenevenementen. Er is ook een zorg om de cultuurtraditie te behouden die de geëerde persoon in haar tijd had, door veel ontmoetingen van lokale kunst en cultuur te organiseren. In het Laurinda Cultureel Centrum hebben veel lokale kunstgroepen de ruimte om hun werk tentoon te stellen. Het project genaamd Receptie begon in 2009 en dankzij dit konden meer dan 100 artistieke groepen hun repetities en artistieke experimenten op het terrein van het huis uitvoeren.",
    laurindaCenterHours: "Van dinsdag tot vrijdag, van 10 tot 19 uur; zaterdag en zondag van 12 tot 19 uur.",
    laurindaCenterTelephone: "+55 (21) 2215-0618",
    chácaraDoCéuTitle: "Chácara do Céu Museum",
    chácaraDoCéuDescription: "Na het Parque das Ruínas kun je het Chácara do Céu Museum bezoeken, dat heel dichtbij is. Het museum bevindt zich in een prachtig groot gebouw met een unieke architectuur en herbergt de privécollectie van een zakenman die vroeger op de plek woonde. De locatie biedt ook een panoramisch uitzicht op de Baai van Guanabara. Het gebouw heeft drie verdiepingen en toont kunstwerken uit verschillende tijdperken en oorsprong. In het museum zijn de tentoonstellingen langdurig, hoewel sommige tijdelijk zijn. De ruimte is gewijd aan Europese kunst en Oosterse kunst. De collectie van het museum omvat tentoonstellingen van Braziliaanse kunstenaars zoals Di Cavalcanti en Portinari; Europese kunstenaars zoals Matisse en Modigliani en Miró en tentoonstellingen van reizigers bekend als Brasiliana met oude kaarten en illustraties van kunstenaars zoals Jean-Baptiste Debret, Chamberlain en Rugendas.",
    chácaraDoCéuHours: "Van woensdag tot maandag, van 12:00 tot 17:00 uur.",
    chácaraDoCéuTickets: "R$6,00 en ze hebben een halve entree.",
    chácaraDoCéuFreeAdmission: "Gratis toegang op woensdag",
    largoDoGuimaraesTitle: "Largo do Guimarães",
    largoDoGuimaraesDescription: "Als je na al dat lopen, omhoog en omlaag, honger krijgt, raad ik je aan om naar Largo do Guimarães te gaan. Wandel daar en waardeer de artistieke beweging die altijd plaatsvindt en misschien ontmoet je iemand die op straat kunst maakt. Maak van de gelegenheid gebruik om de studio's te bezoeken.",
    gastronomicPointTitle: "Gastronomisch Punt",
    gastronomicPointDescription: "Largo do Guimarães is het gastronomische punt. Iedereen weet dat, dus als je je daar verliest, is het vol met bars en restaurants, een boheemse centrum in de wijk, een zeer coole plek om een late middag door te brengen en te genieten van de sfeer van Santa Teresa.",
    santaTeresaTram: "Santa Teresa Tram",
    santaTeresaTramDescription: "De beste tip is zonder twijfel om de Santa Teresa Tram te nemen en de Arcos da Lapa te passeren terwijl je geniet van het prachtige uitzicht van de rit. Geniet van de ligging van de wijk en laat je betoveren door de uitzichten op het centrum van de stad en de Baai van Guanabara, de musea en het vliegveld.",
    visitTramMuseum: "Bezoek het Tram Museum",
    visitTramMuseumDescription: "Het museum heeft foto's, onderdelen en modellen van de tram, en zelfs een oude levensgrote ezelgetrokken tram. Het museum bevindt zich bij de ingang van de Santa Teresa garage en het onderhoudscentrum van de kabelbaan.",
    tijucaNationalForest: "Tijuca Nationaal Park en Corcovado",
    tijucaNationalForestDescription: "De wijk heeft toegang tot het Tijuca Nationaal Park en de Corcovado, twee plaatsen die absoluut de moeite waard zijn.",
//    localTipsTitle: "Santa Teresa in Rio de Janeiro – Lokale Tips voor de Buurt",
//    localTipsDescription: "Weet je niet wat je moet doen in Rio de Janeiro? Een van de belangrijkste attracties van Rio is degene waar niet veel over wordt gesproken, maar die naar mijn mening een van de meest charmante buurten is die ik ooit heb bezocht: Santa Teresa! Vlak naast het drukke stadscentrum ligt een heuvel die de rustige en artistieke wijk Santa Teresa in Rio de Janeiro herbergt – met een uitzicht op de stad dat je adem zal benemen. We vertellen je alle details!"
};

export default nl;
