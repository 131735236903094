import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css'; // Ensure this path is correct
import gbFlag from '../assets/images/flags/gb.svg';
import frFlag from '../assets/images/flags/fr.svg';
import nlFlag from '../assets/images/flags/nl.svg';
import ptFlag from '../assets/images/flags/pt.svg';
import deFlag from '../assets/images/flags/de.svg';
import esFlag from '../assets/images/flags/es.svg'; // Spanish flag
import { useTranslation } from '../utils/TranslationProvider'; // Use the custom hook

const Header = () => {
    const { t, setLanguage, language } = useTranslation(); // Use the custom hook

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    const getFlagClass = (lang) => {
        return language === lang ? 'flag-icon active' : 'flag-icon';
    };

    return (
        <header className="header">
            <div className="header-inner">
                <nav className="nav">
                    <NavLink to="/" end className={({ isActive }) => (isActive ? 'active' : '')}>{t('home')}</NavLink>
                    <NavLink to="/description" className={({ isActive }) => (isActive ? 'active' : '')}>{t('descriptionPage')}</NavLink>
                    <NavLink to="/media" className={({ isActive }) => (isActive ? 'active' : '')}>{t('media')}</NavLink>
                    <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active' : '')}>{t('contact')}</NavLink>
                    <NavLink to="/neighborhood" className={({ isActive }) => (isActive ? 'active' : '')}>{t('neighborhoodTitle')}</NavLink> {/* Changed to neighborhoodTitle */}
                </nav>
                <div className="language-selector">
                    <img
                        src={gbFlag}
                        alt="English"
                        className={getFlagClass('en')}
                        onClick={() => handleLanguageChange('en')}
                    />
                    <img
                        src={frFlag}
                        alt="French"
                        className={getFlagClass('fr')}
                        onClick={() => handleLanguageChange('fr')}
                    />
                    <img
                        src={nlFlag}
                        alt="Dutch"
                        className={getFlagClass('nl')}
                        onClick={() => handleLanguageChange('nl')}
                    />
                    <img
                        src={ptFlag}
                        alt="Portuguese"
                        className={getFlagClass('pt')}
                        onClick={() => handleLanguageChange('pt')}
                    />
                    <img
                        src={deFlag}
                        alt="German"
                        className={getFlagClass('de')}
                        onClick={() => handleLanguageChange('de')}
                    />
                    <img
                        src={esFlag} // Spanish flag
                        alt="Spanish"
                        className={getFlagClass('es')}
                        onClick={() => handleLanguageChange('es')}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
