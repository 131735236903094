// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import DescriptionScreen from './screens/DescriptionScreen';
import MediaScreen from './screens/MediaScreen';
import ContactScreen from './screens/ContactScreen';
import NeighborhoodScreen from './screens/NeighborhoodScreen';
import Hero from './components/Hero';
import { TranslationProvider } from './utils/TranslationProvider'; // Import TranslationProvider
import './styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => {
    const [language, setLanguage] = React.useState('en'); // Default to English

    return (
        <TranslationProvider>
            <Router>
                <div>
                    <Header language={language} setLanguage={setLanguage} />
                    <Routes>
                        <Route path="/" element={<Hero />} />
                        <Route path="/description" element={<DescriptionScreen language={language} />} />
                        <Route path="/media" element={<MediaScreen />} />
                        <Route path="/contact" element={<ContactScreen language={language} />} />
                        <Route path="/neighborhood" element={<NeighborhoodScreen />} /> {/* New route */}
                    </Routes>
                </div>
            </Router>
        </TranslationProvider>
    );
};

export default App;
