import React from 'react';
import './Hero.css'; // Adjust path if necessary
import heroImage from '../assets/images/guesthouseinrio.jpg'; // Path to your hero image

const Hero = () => {
    const phoneNumber = "+5521992761110"; // Your WhatsApp number in international format
    const message = encodeURIComponent("Hello, I found your guesthouse on guesthouseforsaleinrio.com and I would like to schedule a tour.");
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

    return (
        <div className="hero-container" style={{ backgroundImage: `url(${heroImage})` }}>
            <div className="hero-overlay"></div>
            <div className="hero-content">
                <h1>Exclusive Guest House for Sale in Santa Teresa</h1>
                <p>Discover the perfect property with 10 ensuite rooms, a recently rebuilt historical building, and a stunning rooftop terrace.</p>
                <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                    <button className="cta-button">Schedule a Tour</button>
                </a>
                <div className="additional-info">
                    <p><strong>Price:</strong> R$2.400.000</p>
                    <p><strong>Location:</strong> Santa Teresa, Rio de Janeiro</p>
                </div>
            </div>
        </div>
    );
};

export default Hero;
