const es = {
    title: "Villa Santa Teresa",
    description: "Sumérgete en un mundo donde el lujo moderno se encuentra con el encanto histórico con esta impresionante villa en el corazón de Santa Teresa, Río de Janeiro...",
    featuresTitle: "Características:",
    features: [
        "Ubicación privilegiada en el histórico y artístico barrio de Santa Teresa",
        "Acceso inmediato al encantador tranvía Bonde de Santa Teresa",
        "A solo 5 minutos a pie de la impresionante Escadaria Selarón",
        "Edificio principal de lujo con 4 habitaciones en suite, incluidas 2 con vistas impresionantes",
        "Cocina para huéspedes independiente y 6 suites adicionales en el anexo con una terraza en la azotea que ofrece vistas impresionantes y hermosos amaneceres",
        "Simetría arquitectónica con un camino que conduce a la entrada principal",
        "Perfectamente adecuada para una casa de huéspedes/pousada, con permiso completo para operar"
    ],
    price: "Precio: R$ 2.400.000,00",
    home: 'Inicio',
    descriptionPage: 'Descripción',
    media: 'Medios',
    contact: 'Contacto',
    contactTitle: "Contáctenos",
    contactMessage: "Por favor complete el formulario a continuación para ponerse en contacto con nosotros.",
    nameLabel: "Nombre",
    emailLabel: "Correo Electrónico",
    messageLabel: "Mensaje",
    namePlaceholder: "Tu Nombre",
    emailPlaceholder: "Tu Correo Electrónico",
    messagePlaceholder: "Tu Mensaje",
    pricesInOtherCurrencies: "Precios en otras monedas:",
    sendButton: "Enviar",
    successMessage: "¡Tu mensaje ha sido enviado con éxito!",
    errorMessage: "Hubo un error al enviar tu mensaje. Por favor, inténtalo de nuevo.",
    neighborhoodTitle: "Explora el Barrio",
    neighborhoodDescription: "Descubre la vibrante comunidad alrededor de nuestra casa de huéspedes. Conoce las atracciones locales, la gastronomía y las opciones de entretenimiento que hacen de Santa Teresa un lugar emocionante para vivir.",
    overviewTitle: "Resumen",
    overviewDescription: "Descubre el encantador barrio de Santa Teresa, conocido por su atmósfera bohemia, su rica herencia cultural y su encanto histórico. Ubicado en el corazón de Río de Janeiro, esta vibrante área es un refugio para artistas, músicos y cualquier persona que busque experimentar una mezcla única de encanto antiguo y creatividad contemporánea.",
    historyTitle: "Historia",
    historyDescription: "Santa Teresa tiene una rica historia que se remonta a principios del siglo XX. Una vez un distrito de moda para la élite de Río, sus calles estrechas y casas históricas reflejan la grandeza de su pasado. Hoy en día, es un tesoro cultural que preserva su arquitectura histórica mientras abraza la expresión artística moderna.",
    cultureTitle: "Cultura",
    cultureDescription: "La escena cultural en Santa Teresa es una mezcla dinámica de influencias tradicionales y contemporáneas. Desde su vibrante arte callejero hasta su animada música samba, el barrio ofrece una experiencia cultural que celebra tanto su herencia como su continua evolución creativa. La comunidad local fomenta activamente las actividades artísticas y culturales, convirtiéndolo en un punto cultural destacado en Río.",
    landmarksTitle: "Lugares de Interés",
    landmarksDescription: "Santa Teresa alberga varios lugares de interés notables, incluyendo la icónica Escadaria Selarón y los históricos Arcos da Lapa. Estos lugares no solo ofrecen una visión de la historia del área, sino que también proporcionan impresionantes escenarios para fotos y exploración cultural. Cada sitio contribuye al carácter único y la importancia histórica del barrio.",
    localCuisineTitle: "Cocina Local",
    localCuisineDescription: "La escena culinaria en Santa Teresa es tan diversa como su cultura. Desde la comida brasileña tradicional hasta las innovadoras creaciones gastronómicas, los comedores locales ofrecen una deliciosa gama de sabores. Explora encantadores cafés y restaurantes que reflejan la rica herencia culinaria del barrio y las tendencias contemporáneas.",
    thingsToDoTitle: "Cosas para Hacer",
    thingsToDoDescription: "Santa Teresa ofrece una variedad de actividades para los visitantes. Ya sea que te interese explorar galerías de arte, disfrutar de música en vivo o simplemente pasear por sus pintorescas calles, hay algo para todos. La vibrante atmósfera del barrio y sus amables habitantes lo convierten en un lugar ideal para pasar un día descubriendo gemas ocultas.",
    artGalleriesTitle: "Galerías de Arte",
    artGalleriesDescription: "Las galerías de arte en Santa Teresa exhiben una variedad de arte local e internacional. Estas galerías suelen estar ubicadas en encantadores edificios históricos que añaden a la experiencia estética. Los visitantes pueden disfrutar de exposiciones que presentan desde arte contemporáneo hasta artesanías tradicionales, reflejando el espíritu artístico del barrio.",
    historicBuildingsTitle: "Edificios Históricos",
    historicBuildingsDescription: "Los edificios históricos en Santa Teresa son un testimonio del rico pasado del barrio. Estas estructuras bien conservadas, con sus estilos arquitectónicos únicos, proporcionan una visión de la historia del área y su evolución a lo largo de las décadas. Caminar por las calles de Santa Teresa es como retroceder en el tiempo.",
    eventsFestivalsTitle: "Eventos y Festivales",
    eventsFestivalsDescription: "Santa Teresa es conocida por sus vibrantes eventos y festivales que celebran desde la música y el baile hasta las tradiciones y la comida locales. Estos eventos son una excelente manera de experimentar el espíritu animado y la diversidad cultural del barrio, atrayendo tanto a locales como a visitantes.",
    sambaGuimaraesTitle: "Samba do Guimarães",
    sambaGuimaraesDescription: "Un lugar animado que ofrece auténticas actuaciones de samba en un ambiente íntimo. Conocido por su atmósfera energética y música tradicional, es una visita obligada para cualquiera que busque experimentar la famosa cultura samba de Río.",
    address: "Dirección",
    openingHours: "Horario de Apertura",
    tickets: "Entradas",
    sambaGuimaraesHours: "Lunes a sábado, 7 PM - 2 AM",
    sambaGuimaraesTickets: "R$ 30",
    cineCasalTitle: "Cine Casal",
    cineCasalDescription: "Un cine ecléctico que ofrece una selección diversa de películas y eventos culturales. Es un gran lugar para ver películas independientes y participar en discusiones relacionadas con el cine.",
    telephone: "Teléfono",
    malocaTitle: "Maloca",
    malocaDescription: "Un lugar acogedor conocido por sus platos saludables y orgánicos. Maloca ofrece un ambiente relajante donde puedes disfrutar de comidas frescas y nutritivas.",
    malocaHours: "Lunes a viernes, 11 AM - 4 PM",
    cantinhoTitle: "Cantinho do Açaí",
    cantinhoDescription: "Famoso por sus deliciosos tazones de açaí y batidos. Es un lugar popular entre los locales y visitantes para disfrutar de refrigerios refrescantes y saludables.",
    cantinhoHours: "Lunes a sábado, 10 AM - 6 PM",
    raizesTitle: "Raízes",
    raizesDescription: "Un restaurante vibrante que ofrece platos tradicionales brasileños con un giro moderno. Raízes es conocido por su cocina sabrosa y su ambiente acogedor.",
    raizesWebsite: "www.raizesrestaurant.com",
    explorerTitle: "Explorer",
    explorerDescription: "Una experiencia gastronómica refinada que presenta cocina brasileña contemporánea. Conocido por su ambiente elegante y servicio excepcional, Explorer es una gran opción para una comida especial.",
    explorerHours: "Martes a domingo, 12 PM - 11 PM",
    aprazivelTitle: "Aprazível",
    aprazivelDescription: "Un restaurante de lujo con vistas impresionantes y un menú que se centra en platos brasileños e internacionales. Aprazível ofrece una experiencia gastronómica sofisticada en un entorno hermoso.",
    cafeAltoTitle: "Café do Alto",
    cafeAltoDescription: "Un encantador café conocido por su delicioso café y comidas ligeras. Es un lugar perfecto para un brunch relajado o un café por la tarde.",
    cafeAltoHours: "Lunes a sábado, 8 AM - 6 PM",
    barMineiroTitle: "Bar Mineiro",
    barMineiroDescription: "Un bar tradicional que ofrece una variedad de bocadillos y bebidas brasileñas. Bar Mineiro es un gran lugar para experimentar los sabores locales en un entorno informal.",
    simplesmenteTitle: "Simplesmente",
    simplesmenteDescription: "Un restaurante popular que sirve platos brasileños clásicos con un enfoque en la simplicidad y el sabor. Simplesmente es conocido por sus comidas abundantes y su ambiente acogedor.",
    ruinsParkTitle: "Parque das Ruínas",
    ruinsParkDescription: "Un sitio histórico que presenta los restos de una antigua mansión convertida en centro cultural. El parque ofrece hermosas vistas de la ciudad y alberga varios eventos culturales.",
    laurindaTitle: "Laurinda",
    laurindaDescription: "Un lugar encantador que ofrece una mezcla de música brasileña tradicional y actuaciones contemporáneas. Laurinda es conocido por su vibrante atmósfera y entretenimiento de alta calidad.",
    chacaraTitle: "Chácara do Céu",
    chacaraDescription: "Un hermoso centro cultural y museo que muestra arte, historia y naturaleza. Chácara do Céu ofrece un entorno sereno y exhibiciones atractivas.",
    chacaraTip: "Consulta el sitio web para conocer las exposiciones actuales y los eventos especiales.",
    largoGuimaraesTitle: "Largo do Guimarães",
    largoGuimaraesDescription: "Una plaza animada conocida por sus actuaciones callejeras y mercados locales. Largo do Guimarães es un gran lugar para disfrutar del ambiente local y probar comida callejera.",
    gastronomicPointTitle: "Puntos Gastronómicos",
    gastronomicPointDescription: "Explora la diversa escena culinaria en Santa Teresa. Desde restaurantes de alta gama hasta gemas ocultas, el barrio ofrece una amplia gama de opciones gastronómicas para todos los gustos.",
    localTipsTitle: "Consejos Locales",
    localTipsDescription: "Aprovecha al máximo tu visita a Santa Teresa con estos consejos locales. Descubre los mejores lugares para comer, entretenerse y vivir experiencias culturales.",
    santaTeresaTramTitle: "Tranvía de Santa Teresa",
    santaTeresaTramDescription: "El consejo más encantador es sin duda tomar el tranvía de Santa Teresa y pasar por los Arcos da Lapa disfrutando de la maravillosa vista del recorrido, disfrutando de la ubicación del barrio y deslumbrándote con las vistas del centro de la ciudad y de la Bahía de Guanabara, museos y el aeropuerto Santos Dumont.",
    tramMuseumTitle: "Museo del Tranvía",
    tramMuseumDescription: "El espacio tiene fotos, piezas y modelos sobre el tranvía e incluso un antiguo tranvía de tracción animal a tamaño real. El museo está ubicado en la entrada del garaje de Santa Teresa y el lugar de mantenimiento del tranvía.",
    tijucaNationalForestTitle: "Bosque Nacional de Tijuca y Corcovado",
    tijucaNationalForestDescription: "El barrio tiene acceso al Parque Nacional de Tijuca y al Corcovado, dos lugares que también vale la pena visitar, sin duda.",
    downloadPdfTitle: "Descargar PDF",
    downloadPdfDescription: "Para obtener información más detallada e imágenes en alta resolución, descarga nuestra guía completa en PDF.",
    noContent: "No hay contenido disponible.",
    error404: "Página no encontrada.",
    backToHome: "Volver al Inicio",
    viewMore: "Ver Más",
    viewLess: "Ver Menos",
    readMore: "Leer Más",
    readLess: "Leer Menos",
    culturalPlacesTitle: "Lugares Culturales",
    gastronomicPointsTitle: "Puntos Gastronómicos",
    highlightsTitle: "Destacados",
    healthyOptionsTitle: "Opciones Saludables",
    refinedOptionsTitle: "Opciones Refinadas",
    traditionalRestaurantsTitle: "Restaurantes Tradicionales",
    attractionsTitle: "Atracciones",
    mapTitle: "Mapa"
};

export default es;
