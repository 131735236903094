const pt = {
    title: "Villa Santa Teresa",
    description: "Entre em um mundo onde o luxo moderno encontra o charme histórico com esta deslumbrante villa no coração de Santa Teresa, Rio de Janeiro...",
    featuresTitle: "Características:",
    features: [
        "Localização privilegiada no bairro histórico e artístico de Santa Teresa",
        "Acesso imediato ao encantador bondinho de Santa Teresa",
        "Apenas 5 minutos a pé da deslumbrante Escadaria Selarón",
        "Luxuoso edifício principal com 4 suítes, incluindo 2 com vistas deslumbrantes",
        "Cozinha separada para hóspedes e 6 suítes adicionais no anexo com um terraço na cobertura oferecendo vistas de tirar o fôlego e lindas amanheceres",
        "Simetria arquitetônica com um caminho levando à entrada principal",
        "Perfeitamente adequado para uma pousada, com permissão total para operação"
    ],
    price: "Preço: R$ 2.400.000,00",
    home: 'Início',
    descriptionPage: 'Descrição',
    media: 'Mídia',
    contact: 'Contato',
    contactTitle: "Entre em Contato",
    contactMessage: "Por favor, preencha o formulário abaixo para entrar em contato conosco.",
    nameLabel: "Nome",
    emailLabel: "E-mail",
    messageLabel: "Mensagem",
    namePlaceholder: "Seu Nome",
    emailPlaceholder: "Seu E-mail",
    messagePlaceholder: "Sua Mensagem",
    pricesInOtherCurrencies: "Preços em outras moedas:",
    sendButton: "Enviar",
    successMessage: "Sua mensagem foi enviada com sucesso!",
    errorMessage: "Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente.",
    neighborhoodTitle: "Explore o Bairro",
    neighborhoodDescription: "Descubra a vibrante comunidade ao redor da nossa pousada. Saiba mais sobre atrações locais, restaurantes e opções de entretenimento que fazem de Santa Teresa um lugar emocionante para se viver.",
    tram: "Bondinho de Santa Teresa",
    tramDescription: "A melhor dica é, sem dúvida, pegar o Bondinho de Santa Teresa e passar pelos Arcos da Lapa, apreciando a vista maravilhosa do passeio. Aproveite a localização do bairro e se encante com as vistas do centro da cidade e da Baía de Guanabara, museus e aeroporto.",
    tramMuseum: "Visite o Museu do Bonde",
    tramMuseumDescription: "O espaço possui fotos, peças e modelos sobre o bonde e até um antigo bonde puxado por burro em tamanho real. O museu está localizado na entrada da garagem e do local de manutenção do bondinho de Santa Teresa.",
    tijucaForest: "Parque Nacional da Tijuca e Corcovado",
    tijucaForestDescription: "O bairro tem acesso ao Parque Nacional da Tijuca e ao Corcovado, dois lugares que também valem a visita, com certeza.",
    localTipsTitle: "Santa Teresa no Rio de Janeiro – Dicas Locais do Bairro",
    localTipsDescription: "Não sabe o que fazer no Rio de Janeiro? Uma das principais atrações do Rio é aquela sobre a qual não se fala muito, mas que, na minha opinião, é um dos bairros mais charmosos que já visitei: Santa Teresa! Ao lado do movimentado centro da cidade está uma colina que abriga o tranquilo e artístico bairro de Santa Teresa no Rio de Janeiro – com uma vista da cidade que vai te deixar sem fôlego. Vamos te contar todos os detalhes!",
    sambaGuimaraesTitle: "Samba dos Guimarães",
    sambaGuimaraesDescription: "O Samba dos Guimarães é uma tradição no bairro de Santa Teresa. O samba é o estilo musical mais conhecido do Rio de Janeiro, e como você pode visitar o Rio sem experimentar uma noite de samba? Você deve absolutamente aproveitar uma noite de samba, que fica ainda mais especial neste bairro histórico e charmoso.",
    sambaGuimaraesHours: "Sábados das 19:30 às 01:30.",
    sambaGuimaraesTickets: "Preço de entrada: Entre R$20 e R$30.",
    cineCasalTitle: "Cine Casal Santa",
    cineCasalDescription: "Para os amantes de cinema, o Cine Casal Santa é uma visita obrigatória. É um espaço pequeno e aconchegante. O que é especial é que são exibidos filmes alternativos, longe das produções comerciais.",
    cineCasalTelephone: "+55 (21) 2222-0203",
    healthyOptionsTitle: "Opções de Alimentação Saudável",
    malocaTitle: "Maloca Gastrobar",
    malocaDescription: "Um lugar com uma atmosfera muito agradável, e se você é vegetariano, esta é uma ótima opção.",
    malocaHours: "De terça a quinta das 12:00 às 22:00; Sexta a domingo das 12:00 à meia-noite.",
    malocaTelephone: "+55 (21) 3174-3597",
    cantinhoTitle: "Cantinho de Santa",
    cantinhoDescription: "Um espaço para alimentação saudável com opções para vegetarianos e veganos. Os ingredientes usados são todos orgânicos e os preços são muito acessíveis, o que torna o lugar ainda mais especial. Venha e aproveite o agradável balcão que o local oferece!",
    cantinhoHours: "De terça a quinta das 10:30 às 18:30; Sexta e sábado das 10:00 às 22:00.",
    cantinhoTelephone: "+55 (21) 3497-5122",
    raizesTitle: "Raízes do Brasil",
    raizesDescription: "Se você for a Santa Teresa, precisa visitar este lugar. A localização está conectada ao movimento agroecológico, com pequenos produtores. Com uma decoração muito rústica e comida deliciosa. Oferecem café da roça, almoços (com pratos no sistema self-service a preços muito acessíveis) e lanches à tarde. Também há uma cesta de produtos (mercado). Frequentemente, há também música ao vivo!",
    raizesWebsite: "Confira o site para horários.",
    raizesTelephone: "+55 (21) 3081-4652",
    refinedOptionsTitle: "Opções Gastronômicas Refinadas",
    explorerTitle: "Explorer Bar",
    explorerDescription: "Um bar muito acolhedor para coquetéis, com opções deliciosas no menu, definitivamente vale a visita.",
    explorerHours: "De terça a quinta das 17:00 à meia-noite; Sexta das 17:00 às 01:00; Sábado das 14:00 às 01:00; Domingo das 14:00 à meia-noite.",
    explorerTelephone: "+55 (21) 3264-9665",
    aprazivelTitle: "Aprazível",
    aprazivelDescription: "Se você procura um lugar mais refinado com vistas impressionantes e um menu variado com elementos naturais e tropicais, esta é a escolha perfeita. Com produtos caseiros como chocolates, vinhos, cervejas, cafés e cachaças, é uma excelente recomendação.",
    aprazivelHours: "Quarta a sábado das 12:00 às 23:00; Domingo das 12:00 às 18:00.",
    aprazivelTelephone: "+55 (21) 2508-9174",
    traditionalRestaurantsTitle: "Restaurantes Tradicionais",
    cafeAltoTitle: "Café do Alto",
    cafeAltoDescription: "Uma boa opção de restaurante. Um lugar verdadeiramente único no Rio de Janeiro! Realmente gostamos deste café. O local oferece cozinha tradicional do Nordeste do país.",
    cafeAltoHours: "Segunda e terça das 12:00 às 21:00; Quinta e sexta das 12:00 às 21:00; Sábado e domingo das 09:00 às 20:00.",
    cafeAltoTelephone: "+55 (21) 2507-3172, +55 (21) 9802-41666",
    barMineiroTitle: "Bar do Mineiro",
    barMineiroDescription: "Um local muito conhecido, visitado tanto pelos moradores quanto pelos turistas. É um bar simples, e as pessoas geralmente ficam em pé enquanto bebem cerveja, pois há poucas mesas. Mais informal? O local também oferece uma feijoada aos sábados.",
    barMineiroHours: "De terça a domingo das 11:00 às 23:00.",
    barMineiroTelephone: "+55 (21) 2221-9227",
    simplesmenteTitle: "Simplesmente",
    simplesmenteDescription: "Um dos favoritos do bairro, com a já mencionada Bar Mineiro. Possui um menu razoável e costuma ser mais tranquilo, ideal para petiscos e cervejas realmente frescas.",
    simplesmenteHours: "De terça a quinta das 16:00 às 03:30; Sábado das 13:00 às 03:30 e domingo das 13:00 às 02:00.",
    simplesmenteTelephone: "+55 (21) 2507-1365",
    ruinsParkTitle: "Parque das Ruínas",
    ruinsParkDescription: "Uma das principais atrações do bairro é o museu Parque das Ruínas, sem dúvida um local imperdível. Esta foi a residência da cantora Laurinda dos Santos Lobo e lá aconteciam muitas festas e eventos com artistas famosos da sociedade carioca, como Tarsila do Amaral, no século XIX, e até presidentes foram recebidos. Em 1993, o edifício foi declarado um monumento que preserva a estrutura em ruínas e tijolos aparentes. De lá, temos uma vista incrível e privilegiada de 360º da Baía de Guanabara, do centro da cidade, e uma dica: as fotos de lá são incríveis. O local sempre oferece programação gratuita com exposições e teatro, apresentações de música e muito mais atrações. Você também pode fazer uma pausa para um café dentro do local e desfrutar do seu café com uma vista deslumbrante.",
    ruinsParkAddress: "Rua Murtinho Nobre, 169 – Santa Teresa",
    ruinsParkHours: "De terça a domingo, das 8h às 18h.",
    ruinsParkTickets: "Entrada Gratuita",
    laurindaCenterTitle: "Centro Cultural Laurinda Santos Lobo",
    laurindaCenterDescription: "Laurinda recebeu outra homenagem no mesmo bairro, com um Centro Cultural que leva seu nome. O Centro Cultural Laurinda Santos Lobo foi criado em 1979 por um grupo de pessoas muito importantes da região. O Centro Cultural funciona em uma grande casa rosa e Laurinda nunca viveu lá. Na verdade, a grande casa foi construída em 1907 pela Baronesa de Parina e foi a residência de políticos importantes da época. Mas a homenagem a Laurinda ocorreu em um momento em que sua antiga casa, hoje Parque das Ruínas, estava abandonada. Conhecido apenas como “A Laurinda”, o centro cultural é um dos espaços do bairro onde mais se faz referência à memória de Santa Teresa. Há exposições, oficinas de dança e música, projeções fotográficas, apresentações teatrais, atividades para crianças, recitais de piano e alguns eventos ao ar livre. Há também uma preocupação em manter a tradição de incentivo à cultura que a homenageada tinha em sua época, proporcionando muitos encontros de arte e cultura locais. No Centro Cultural Laurinda muitos grupos de arte locais têm espaço para expor seu trabalho. O projeto chamado Recepção começou em 2009 e, graças a ele, mais de 100 grupos artísticos puderam realizar suas ensaios e experimentos artísticos no terreno da casa.",
    laurindaCenterHours: "De terça a sexta-feira, das 10h às 19h; sábado e domingo, das 12h às 19h.",
    laurindaCenterTelephone: "+55 (21) 2215-0618",
    chácaraDoCéuTitle: "Museu Chácara do Céu",
    chácaraDoCéuDescription: "Após o Parque das Ruínas, você pode visitar o Museu Chácara do Céu, que fica bem perto. O museu funciona em um belo grande edifício, com uma arquitetura singular e possui a coleção privada de um empresário que costumava viver no local. O lugar também oferece uma vista panorâmica da Baía de Guanabara. O prédio tem três andares e exibe peças de arte de diferentes períodos e origens. No museu, as exposições são duradouras, embora algumas sejam temporárias. O espaço dedica um andar à arte europeia e à arte oriental. A coleção do museu inclui exposições de artistas brasileiros, como Di Cavalcanti e Portinari; artistas europeus, como Matisse e Modigliani e Miró e exposições de obras de viajantes conhecidos como Brasiliana, com mapas antigos e ilustrações de artistas como Jean-Baptiste Debret, Chamberlain e Rugendas.",
    chácaraDoCéuHours: "De quarta a segunda-feira, das 12h às 17h.",
    chácaraDoCéuTickets: "R$6,00 e há meia-entrada.",
    chácaraDoCéuFreeAdmission: "Entrada gratuita às quartas-feiras",
    largoDoGuimaraesTitle: "Largo do Guimarães",
    largoDoGuimaraesDescription: "Se depois de toda essa caminhada, subindo e descendo, você começar a sentir fome, minha sugestão é ir para o Largo do Guimarães. Caminhe por lá e aprecie o movimento artístico que sempre acontece e talvez você encontre alguém fazendo arte na rua. Aproveite para visitar os ateliês.",
    gastronomicPointTitle: "Ponto Gastronômico",
    gastronomicPointDescription: "Largo do Guimarães é o ponto gastronômico. Todo mundo sabe disso, então se você se perder por lá, é cheio de bares e restaurantes, um centro boêmio no bairro, um lugar muito legal para passar uma tarde e aproveitar a atmosfera de Santa Teresa.",
    santaTeresaTram: "Bondinho de Santa Teresa",
    santaTeresaTramDescription: "A melhor dica é, sem dúvida, pegar o Bondinho de Santa Teresa e passar pelos Arcos da Lapa, apreciando a vista maravilhosa do passeio. Aproveite a localização do bairro e se encante com as vistas do centro da cidade e da Baía de Guanabara, museus e aeroporto.",
    visitTramMuseum: "Visite o Museu do Bonde",
    visitTramMuseumDescription: "O museu possui fotos, peças e modelos sobre o bonde e até um antigo bonde puxado por burro em tamanho real. O museu está localizado na entrada da garagem e do local de manutenção do bondinho de Santa Teresa.",
    tijucaNationalForest: "Parque Nacional da Tijuca e Corcovado",
    tijucaNationalForestDescription: "O bairro tem acesso ao Parque Nacional da Tijuca e ao Corcovado, dois lugares que também valem a visita, com certeza.",
//    localTipsTitle: "Santa Teresa no Rio de Janeiro – Dicas Locais do Bairro",
//    localTipsDescription: "Não sabe o que fazer no Rio de Janeiro? Uma das principais atrações do Rio é aquela sobre a qual não se fala muito, mas que, na minha opinião, é um dos bairros mais charmosos que já visitei: Santa Teresa! Ao lado do movimentado centro da cidade está uma colina que abriga o tranquilo e artístico bairro de Santa Teresa no Rio de Janeiro – com uma vista da cidade que vai te deixar sem fôlego. Vamos te contar todos os detalhes!",
};

export default pt;
