// src/utils/TranslationProvider.js
import React, { createContext, useContext, useState } from 'react';
import en from './translations/en';
import pt from './translations/pt';
import fr from './translations/fr';
import nl from './translations/nl';
import de from './translations/de';
import es from './translations/es';

const translations = { en, pt, fr, nl, de, es };

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState('en'); // Default language

    const t = (key) => {
        // Retrieve translation from the current language, fallback to English if not found
        return translations[language][key] || translations['en'][key] || key;
    };

    return (
        <TranslationContext.Provider value={{ t, setLanguage, language }}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = () => useContext(TranslationContext);
