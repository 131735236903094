const fr = {
    title: "Villa Santa Teresa",
    description: "Entrez dans un monde où le luxe moderne rencontre le charme historique avec cette superbe villa au cœur de Santa Teresa, Rio de Janeiro...",
    featuresTitle: "Caractéristiques :",
    features: [
        "Emplacement privilégié dans le quartier historique et artistique de Santa Teresa",
        "Accès immédiat au charmant tramway Bonde de Santa Teresa",
        "À seulement 5 minutes à pied de l'impressionnant Escadaria Selarón",
        "Bâtiment principal luxueux avec 4 chambres en suite, dont 2 avec des vues imprenables",
        "Cuisine séparée pour les invités et 6 suites supplémentaires dans l'annexe avec une terrasse sur le toit offrant des vues à couper le souffle et de beaux levers de soleil",
        "Symétrie architecturale avec un chemin menant à l'entrée principale",
        "Parfaitement adaptée pour une maison d'hôtes/pousada, avec pleine autorisation d'exploitation"
    ],
    price: "Prix : R$ 2.400.000,00",
    home: 'Accueil',
    descriptionPage: 'Description',
    media: 'Médias',
    contact: 'Contact',
    contactTitle: "Contactez-nous",
    contactMessage: "Veuillez remplir le formulaire ci-dessous pour nous contacter.",
    nameLabel: "Nom",
    emailLabel: "Email",
    messageLabel: "Message",
    namePlaceholder: "Votre Nom",
    emailPlaceholder: "Votre Email",
    messagePlaceholder: "Votre Message",
    pricesInOtherCurrencies: "Prix dans d'autres devises :",
    sendButton: "Envoyer",
    successMessage: "Votre message a été envoyé avec succès !",
    errorMessage: "Une erreur s'est produite lors de l'envoi de votre message. Veuillez réessayer.",
    neighborhoodTitle: "Découvrez le Quartier",
    neighborhoodDescription: "Découvrez la communauté vibrante autour de notre maison d'hôtes. Apprenez-en plus sur les attractions locales, les restaurants et les options de divertissement qui font de Santa Teresa un endroit passionnant à vivre.",
    overviewTitle: "Aperçu",
    overviewDescription: "Découvrez le charmant quartier de Santa Teresa, réputé pour son ambiance bohème, son riche patrimoine culturel et son charme historique. Niché au cœur de Rio de Janeiro, ce quartier vibrant est un havre pour les artistes, les musiciens et tous ceux qui cherchent à vivre une expérience unique mêlant charme d'antan et créativité contemporaine.",
    historyTitle: "Histoire",
    historyDescription: "Santa Teresa a une histoire riche qui remonte au début du XXe siècle. Autrefois un quartier à la mode pour l'élite de Rio, ses rues étroites et ses maisons historiques reflètent la grandeur de son passé. Aujourd'hui, il est un trésor culturel qui préserve son architecture historique tout en embrassant l'expression artistique moderne.",
    cultureTitle: "Culture",
    cultureDescription: "La scène culturelle de Santa Teresa est un mélange dynamique d'influences traditionnelles et contemporaines. De son art de rue vibrant à sa musique samba animée, le quartier offre une expérience culturelle qui célèbre à la fois son héritage et son évolution créative continue. La communauté locale favorise activement les activités artistiques et culturelles, en faisant un point chaud culturel à Rio.",
    landmarksTitle: "Points de Repère",
    landmarksDescription: "Santa Teresa abrite plusieurs points de repère notables, y compris l'emblématique Escadaria Selarón et les historiques Arcos da Lapa. Ces points de repère offrent non seulement un aperçu de l'histoire de la région, mais aussi des décors époustouflants pour des photos et des explorations culturelles. Chaque site contribue au caractère unique et à l'importance historique du quartier.",
    localCuisineTitle: "Cuisine Locale",
    localCuisineDescription: "La scène culinaire de Santa Teresa est aussi diverse que sa culture. De la cuisine brésilienne traditionnelle aux créations gastronomiques innovantes, les restaurants locaux offrent une délicieuse gamme de saveurs. Explorez des cafés et des restaurants charmants qui reflètent le riche patrimoine culinaire du quartier et les tendances contemporaines.",
    thingsToDoTitle: "Activités",
    thingsToDoDescription: "Santa Teresa offre une variété d'activités pour les visiteurs. Que vous soyez intéressé par l'exploration de galeries d'art, l'écoute de musique live ou simplement la promenade dans ses rues pittoresques, il y en a pour tous les goûts. L'atmosphère vibrante du quartier et ses habitants chaleureux en font un endroit idéal pour passer une journée à découvrir des trésors cachés.",
    artGalleriesTitle: "Galeries d'Art",
    artGalleriesDescription: "Les galeries d'art de Santa Teresa exposent une gamme d'art local et international. Ces galeries sont souvent situées dans des bâtiments historiques charmants qui ajoutent à l'expérience esthétique. Les visiteurs peuvent profiter d'expositions qui présentent tout, de l'art contemporain aux métiers traditionnels, reflétant l'esprit artistique du quartier.",
    historicBuildingsTitle: "Bâtiments Historiques",
    historicBuildingsDescription: "Les bâtiments historiques de Santa Teresa témoignent du riche passé du quartier. Ces structures bien préservées, avec leurs styles architecturaux uniques, offrent un aperçu de l'histoire de la région et de son évolution au fil des décennies. Marcher dans les rues de Santa Teresa, c'est comme remonter dans le temps.",
    eventsFestivalsTitle: "Événements et Festivals",
    eventsFestivalsDescription: "Santa Teresa est connue pour ses événements et festivals vibrants qui célèbrent tout, de la musique et de la danse aux traditions et à la nourriture locales. Ces événements sont un excellent moyen de découvrir l'esprit animé et la diversité culturelle du quartier, attirant à la fois les locaux et les visiteurs.",
    sambaGuimaraesTitle: "Samba do Guimarães",
    sambaGuimaraesDescription: "Un lieu animé offrant des performances de samba authentiques dans un cadre intime. Connu pour son atmosphère énergique et sa musique traditionnelle, c'est un incontournable pour quiconque souhaite découvrir la célèbre culture samba de Rio.",
    address: "Adresse",
    openingHours: "Heures d'ouverture",
    tickets: "Billets",
    sambaGuimaraesHours: "Lundi à samedi, 19h - 2h",
    sambaGuimaraesTickets: "R$ 30",
    cineCasalTitle: "Cine Casal",
    cineCasalDescription: "Un cinéma éclectique offrant une sélection diverse de films et d'événements culturels. C'est un excellent endroit pour voir des films indépendants et participer à des discussions sur le cinéma.",
    telephone: "Téléphone",
    malocaTitle: "Maloca",
    malocaDescription: "Un endroit confortable connu pour ses plats sains et biologiques. Maloca offre une atmosphère relaxante où vous pouvez profiter de repas frais et nutritifs.",
    malocaHours: "Lundi à vendredi, 11h - 16h",
    cantinhoTitle: "Cantinho do Açaí",
    cantinhoDescription: "Célèbre pour ses délicieux bols d'açaï et ses smoothies. C'est un endroit populaire parmi les locaux et les visiteurs pour profiter de collations rafraîchissantes et saines.",
    cantinhoHours: "Lundi à samedi, 10h - 18h",
    raizesTitle: "Raízes",
    raizesDescription: "Un restaurant vibrant offrant des plats brésiliens traditionnels avec une touche moderne. Raízes est connu pour sa cuisine savoureuse et son ambiance chaleureuse.",
    raizesWebsite: "www.raizesrestaurant.com",
    explorerTitle: "Explorer",
    explorerDescription: "Une expérience gastronomique raffinée mettant en vedette la cuisine brésilienne contemporaine. Connu pour son cadre élégant et son service exceptionnel, Explorer est un excellent choix pour un repas spécial.",
    explorerHours: "Mardi à dimanche, 12h - 23h",
    aprazivelTitle: "Aprazível",
    aprazivelDescription: "Un restaurant haut de gamme avec des vues imprenables et un menu axé sur des plats brésiliens et internationaux. Aprazível offre une expérience gastronomique sophistiquée dans un cadre magnifique.",
    cafeAltoTitle: "Café do Alto",
    cafeAltoDescription: "Un charmant café connu pour son délicieux café et ses repas légers. C'est un endroit parfait pour un brunch détendu ou un café l'après-midi.",
    cafeAltoHours: "Lundi à samedi, 8h - 18h",
    barMineiroTitle: "Bar Mineiro",
    barMineiroDescription: "Un bar traditionnel offrant une variété de collations et de boissons brésiliennes. Bar Mineiro est un excellent endroit pour découvrir les saveurs locales dans un cadre décontracté.",
    simplesmenteTitle: "Simplesmente",
    simplesmenteDescription: "Un restaurant populaire servant des plats brésiliens classiques avec un accent sur la simplicité et la saveur. Simplesmente est connu pour ses repas copieux et son ambiance accueillante.",
    ruinsParkTitle: "Parque das Ruínas",
    ruinsParkDescription: "Un site historique présentant les vestiges d'un ancien manoir transformé en centre culturel. Le parc offre de belles vues sur la ville et accueille divers événements culturels.",
    laurindaTitle: "Laurinda",
    laurindaDescription: "Un lieu charmant offrant un mélange de musique brésilienne traditionnelle et de performances contemporaines. Laurinda est connu pour son ambiance vibrante et ses divertissements de haute qualité.",
    chacaraTitle: "Chácara do Céu",
    chacaraDescription: "Un beau centre culturel et musée présentant de l'art, de l'histoire et de la nature. Chácara do Céu offre un environnement serein et des expositions engageantes.",
    chacaraTip: "Consultez le site web pour connaître les expositions actuelles et les événements spéciaux.",
    largoGuimaraesTitle: "Largo do Guimarães",
    largoGuimaraesDescription: "Une place animée connue pour ses performances de rue et ses marchés locaux. Largo do Guimarães est un endroit idéal pour profiter de l'ambiance locale et goûter à la nourriture de rue.",
    gastronomicPointTitle: "Points Gastronomiques",
    gastronomicPointDescription: "Explorez la scène culinaire diversifiée de Santa Teresa. Des restaurants haut de gamme aux joyaux cachés, le quartier offre une large gamme d'options de restauration pour tous les goûts.",
    localTipsTitle: "Conseils Locaux",
    localTipsDescription: "Tirez le meilleur parti de votre visite à Santa Teresa avec ces conseils locaux. Découvrez les meilleurs endroits pour dîner, se divertir et vivre des expériences culturelles.",
    santaTeresaTramTitle: "Tramway de Santa Teresa",
    santaTeresaTramDescription: "Le conseil le plus charmant est sans aucun doute de prendre le tramway de Santa Teresa et de passer par les Arcos da Lapa en profitant de la vue magnifique du trajet, en profitant de l'emplacement du quartier et en s'émerveillant des vues du centre-ville et de la baie de Guanabara, des musées et de l'aéroport Santos Dumont.",
    tramMuseumTitle: "Musée du Tramway",
    tramMuseumDescription: "L'espace présente des photos, des pièces et des modèles sur le tramway et même un ancien tramway à traction animale en taille réelle. Le musée est situé à l'entrée du garage de Santa Teresa et du lieu de maintenance du tramway.",
    tijucaNationalForestTitle: "Forêt Nationale de Tijuca et Corcovado",
    tijucaNationalForestDescription: "Le quartier a accès au parc national de Tijuca et au Corcovado, deux lieux qui valent également la visite, sans aucun doute.",
    downloadPdfTitle: "Télécharger le PDF",
    downloadPdfDescription: "Pour des informations plus détaillées et des images en haute résolution, téléchargez notre guide complet en PDF.",
    noContent: "Pas de contenu disponible.",
    error404: "Page non trouvée.",
    backToHome: "Retour à l'Accueil",
    viewMore: "Voir Plus",
    viewLess: "Voir Moins",
    readMore: "Lire Plus",
    readLess: "Lire Moins",
    culturalPlacesTitle: "Lieux Culturels",
    gastronomicPointsTitle: "Points Gastronomiques",
    highlightsTitle: "Points Forts",
    healthyOptionsTitle: "Options Saines",
    refinedOptionsTitle: "Options Raffinées",
    traditionalRestaurantsTitle: "Restaurants Traditionnels",
    attractionsTitle: "Attractions",
    mapTitle: "Carte"
};

export default fr;
