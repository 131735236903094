const de = {
    title: "Villa Santa Teresa",
    description: "Tauchen Sie ein in eine Welt, in der moderner Luxus auf historischen Charme trifft, mit dieser atemberaubenden Villa im Herzen von Santa Teresa, Rio de Janeiro...",
    featuresTitle: "Merkmale:",
    features: [
        "Erstklassige Lage im historischen und künstlerischen Stadtteil Santa Teresa",
        "Unmittelbarer Zugang zur charmanten Bonde de Santa Teresa Straßenbahn",
        "Nur 5 Gehminuten von der beeindruckenden Escadaria Selarón entfernt",
        "Luxuriöses Hauptgebäude mit 4 Suiten, darunter 2 mit atemberaubenden Ausblicken",
        "Separate Gästeküche und 6 weitere Suiten im Anbau mit einer Dachterrasse, die atemberaubende Ausblicke und wunderschöne Sonnenaufgänge bietet",
        "Architektonische Symmetrie mit einem Weg, der zum Haupteingang führt",
        "Perfekt geeignet für eine Gästehaus/Pousada, mit voller Betriebserlaubnis"
    ],
    price: "Preis: R$ 2.400.000,00",
    home: 'Startseite',
    descriptionPage: 'Beschreibung',
    media: 'Medien',
    contact: 'Kontakt',
    contactTitle: "Kontaktieren Sie uns",
    contactMessage: "Bitte füllen Sie das folgende Formular aus, um mit uns in Kontakt zu treten.",
    nameLabel: "Name",
    emailLabel: "E-Mail",
    messageLabel: "Nachricht",
    namePlaceholder: "Ihr Name",
    emailPlaceholder: "Ihre E-Mail",
    messagePlaceholder: "Ihre Nachricht",
    pricesInOtherCurrencies: "Preise in anderen Währungen:",
    sendButton: "Absenden",
    successMessage: "Ihre Nachricht wurde erfolgreich gesendet!",
    errorMessage: "Es gab einen Fehler beim Senden Ihrer Nachricht. Bitte versuchen Sie es erneut.",
    neighborhoodTitle: "Erforschen Sie das Viertel",
    neighborhoodDescription: "Entdecken Sie die lebendige Gemeinschaft rund um unser Gästehaus. Erfahren Sie mehr über lokale Attraktionen, Gastronomie- und Unterhaltungsmöglichkeiten, die Santa Teresa zu einem aufregenden Ort zum Leben machen.",
    tram: "Straßenbahn Santa Teresa",
    tramDescription: "Der charmanteste Tipp ist ohne Zweifel, die Straßenbahn Santa Teresa zu nehmen und die Arcos da Lapa zu überqueren, während Sie die wunderbare Aussicht genießen. Genießen Sie die Lage des Viertels und lassen Sie sich von den Ausblicken auf die Innenstadt der Stadt und die Guanabara-Bucht, Museen und den Flughafen begeistern.",
    tramMuseum: "Besuchen Sie das Straßenbahnmuseum",
    tramMuseumDescription: "Der Raum enthält Fotos, Teile und Modelle der Straßenbahn sowie eine alte lebensgroße von Eseln gezogene Straßenbahn. Das Museum befindet sich am Eingang der Santa Teresa-Garage und des Wartungsorts für die Straßenbahn.",
    tramMuseumHours: "Montag bis Freitag, 9 bis 16:30 Uhr.",
    tijucaForest: "Tijuca Nationalpark und Corcovado",
    tijucaForestDescription: "Das Viertel hat Zugang zum Tijuca Nationalpark und zum Corcovado, zwei Orten, die auf jeden Fall einen Besuch wert sind.",
    localTipsTitle: "Santa Teresa in Rio de Janeiro – Lokale Tipps zum Viertel",
    localTipsDescription: "Wissen Sie nicht, was Sie in Rio de Janeiro unternehmen sollen? Eine der besten Attraktionen von Rio ist eine, über die nicht viele sprechen, aber meiner Meinung nach ist es eines der charmantesten Viertel, die ich je besucht habe: Santa Teresa! Direkt neben dem geschäftigen Stadtzentrum befindet sich ein Berg, der das ruhige und künstlerische Viertel Santa Teresa in Rio de Janeiro beherbergt – mit einem Blick auf die Stadt, der Ihnen den Atem rauben wird. Wir erzählen Ihnen alle Details!",
    sambaGuimaraesTitle: "Samba dos Guimarães",
    sambaGuimaraesDescription: "Der Samba von Guimarães ist eine Tradition im Stadtteil Santa Teresa. Der Samba ist die berühmteste Musikart in Rio de Janeiro, und wie könnte man Rio besuchen, ohne eine Samba-Party zu erleben? Sie müssen eine Sambanacht genießen, die in diesem historischen und charmanten Stadtteil noch besonderer wird.",
    sambaGuimaraesHours: "Samstags von 19:30 bis 01:30 Uhr.",
    sambaGuimaraesTickets: "Eintrittspreise: Zwischen R$20 und R$30.",
    cineCasalTitle: "Cine Casal Santa",
    cineCasalDescription: "Für Reisende, die das Kino lieben, sollten Sie das Cine Casal Santa besuchen. Es ist ein kleiner und gemütlicher Ort. Das Besondere an diesem Ort ist, dass alternative Filme gezeigt werden, fernab vom Mainstream.",
    cineCasalTelephone: "+55 (21) 2222-0203",
    healthyOptionsTitle: "Gesunde Essensoptionen",
    malocaTitle: "Maloca Gastrobar",
    malocaDescription: "Ein Ort mit sehr angenehmer Atmosphäre und wenn Sie Vegetarier sind, ist dies definitiv eine goldene Option.",
    malocaHours: "Dienstag bis Donnerstag von 12 bis 22 Uhr; Freitag bis Sonntag von 12 bis 00 Uhr.",
    malocaTelephone: "+55 (21) 3174-3597",
    cantinhoTitle: "Cantinho de Santa",
    cantinhoDescription: "Ein Raum für gesunde Küche mit Optionen für Vegetarier und Veganer. Die verwendeten Zutaten sind alle biologisch und zu sehr vernünftigen Preisen, was den Ort noch besonderer macht. Besuchen Sie und genießen Sie den gemütlichen Balkon, den der Raum bietet!",
    cantinhoHours: "Dienstag bis Donnerstag, 10:30 bis 18:30 Uhr; Freitag und Samstag von 10 bis 22 Uhr.",
    cantinhoTelephone: "+55 (21) 3497-5122",
    raizesTitle: "Raízes do Brasil",
    raizesDescription: "Wenn Sie nach Santa Teresa gehen, müssen Sie diesen Ort besuchen. Der Raum ist mit der agroökologischen Bewegung verbunden, mit kleinen Bauern. Mit einer sehr rustikalen Einrichtung und köstlichem Essen. Sie bieten Bauernkaffee, Mittagessen (mit Selbstbedienungsgerichten zu sehr guten Preisen) und Snacks am Nachmittag an. Sie bieten auch einen Bauernkorb (Markt) an. Oft gibt es auch Live-Musik!",
    raizesWebsite: "Bitte besuchen Sie die Website für die Öffnungszeiten.",
    raizesTelephone: "+55 (21) 3081-4652",
    refinedOptionsTitle: "Verfeinerte Gastronomische Optionen",
    explorerTitle: "Explorer Bar",
    explorerDescription: "Eine sehr gemütliche Bar für Drinks und mit köstlichen Optionen auf der Speisekarte, es lohnt sich, sie zu besuchen.",
    explorerHours: "Dienstag bis Donnerstag, 17 bis Mitternacht; Freitag von 17 bis 01 Uhr; Samstag von 14 bis 01 Uhr; Sonntag von 14 bis Mitternacht.",
    explorerTelephone: "+55 (21) 3264-9665",
    aprazivelTitle: "Aprazível",
    aprazivelDescription: "Wenn Sie einen exquisiteren Ort mit beeindruckenden Ausblicken und einer vielfältigen Küche suchen, die natürliche und tropische Elemente verwendet, ist dies die perfekte Wahl. Mit hausgemachten Produkten wie Schokolade, Wein, Bier, Kaffee und Cachaça ist es eine großartige Empfehlung.",
    aprazivelHours: "Von Mittwoch bis Samstag, 12 bis 23 Uhr; Sonntag von 12 bis 18 Uhr.",
    aprazivelTelephone: "+55 (21) 2508-9174",
    traditionalRestaurantsTitle: "Traditionelle Restaurants",
    cafeAltoTitle: "Café do Alto",
    cafeAltoDescription: "Eine gute Restaurantoption. Ein wirklich einzigartiger Ort in Rio de Janeiro! Wir lieben dieses Café. Der Ort bietet traditionelle Küche aus dem Nordosten des Landes.",
    cafeAltoHours: "Montag und Dienstag, 12 bis 21 Uhr; Donnerstag und Freitag, 12 bis 21 Uhr; Samstag und Sonntag, 9 bis 20 Uhr.",
    cafeAltoTelephone: "+55 (21) 2507-3172, +55 (21) 9802-41666",
    barMineiroTitle: "Bar do Mineiro",
    barMineiroDescription: "Ein sehr bekannter Ort, der sowohl von Einheimischen als auch von Touristen frequentiert wird. Es ist eine einfache Bar, und die Leute stehen beim Trinken von Bier, da die Tische wenige sind. Gibt es eine informellere Art? Der Ort bietet auch samstags Feijoada an.",
    barMineiroHours: "Dienstag bis Sonntag, 11 bis 23 Uhr.",
    barMineiroTelephone: "+55 (21) 2221-9227",
    simplesmenteTitle: "Simplesmente",
    simplesmenteDescription: "Dies ist einer der Favoriten im Viertel, zusammen mit der zuvor genannten Bar Mineiro. Es hat ein vernünftiges Menü und ist normalerweise ruhiger, ideal für Snacks und ein wirklich kaltes Bier.",
    simplesmenteHours: "Dienstag bis Donnerstag, 16 bis 03:30 Uhr; Samstag von 13 bis 03:30 Uhr und Sonntag von 13 bis 02 Uhr.",
    simplesmenteTelephone: "+55 (21) 2507-1365",
    ruinsParkTitle: "Parque das Ruínas",
    ruinsParkDescription: "Eine der Hauptattraktionen des Viertels ist das Museum Parque das Ruínas (Ruinenpark), das zweifellos ein Muss ist. Es war die Residenz der Sängerin Laurinda dos Santos Lobo und dort fanden viele Partys und Veranstaltungen mit berühmten Künstlern der cariokischen Gesellschaft wie Tarsila do Amaral im 19. Jahrhundert statt und es empfing sogar Präsidenten. 1993 wurde das Gebäude zum Denkmal erklärt, das die Struktur in Ruinen und sichtbaren Ziegeln bewahrt. Von dort haben wir einen atemberaubenden und privilegierten 360º-Blick auf die Guanabara-Bucht, die Innenstadt der Stadt, und ein Tipp: die Fotos von dort sind beeindruckend. Der Ort bietet immer ein kostenloses Programm mit Ausstellungen und Theater, Musikdarstellungen und vielen weiteren Attraktionen. Sie können auch eine Pause für einen Kaffee im Inneren des Gebäudes machen und Ihren Kaffee mit Aussicht genießen.",
    ruinsParkAddress: "Rua Murtinho Nobre, 169 – Santa Teresa",
    ruinsParkHours: "Von Dienstag bis Sonntag, von 8 bis 18 Uhr.",
    ruinsParkTickets: "Kostenloser Eintritt",
    laurindaCenterTitle: "Municipal Cultural Center Laurinda Santos Lobo",
    laurindaCenterDescription: "Laurinda erhielt ein weiteres Denkmal im gleichen Viertel, mit einem Kulturzentrum, das ihren Namen trägt. Das Centro Cultural Municipal Laurinda Santos Lobo wurde 1979 von einer Gruppe sehr wichtiger Personen aus der Gegend gegründet. Das Kulturzentrum befindet sich in einem großen rosa Haus, und Laurinda hat nie an diesem Ort gewohnt. Tatsächlich wurde das große Haus 1907 von der Baronesa de Parina erbaut und war das Zuhause von wichtigen Politikern der damaligen Zeit. Aber die Ehrung Laurindas geschah zu einer Zeit, als ihr ehemaliges Haus, das heute Parque das Ruínas ist, verlassen war. Bekannt als „Das Laurinda“, ist das Kulturzentrum einer der Orte im Viertel, der am meisten an die Erinnerung an Santa Teresa erinnert. Es gibt Ausstellungen, Tanz- und Musikworkshops, Fotoausstellungen, Theateraufführungen, Kinderaktivitäten, Klavierrezitale und einige Freiluftveranstaltungen. Es gibt auch ein Anliegen, die Tradition der Kulturförderung, die die Ehrengedenkende in ihrer Zeit hatte, aufrechtzuerhalten, indem viele Treffen von Kunst und Kultur vor Ort organisiert werden. Im Laurinda-Kulturzentrum haben viele lokale Kunstgruppen die Möglichkeit, ihre Arbeiten auszustellen. Das Projekt namens Empfang begann 2009, und dank ihm konnten mehr als 100 künstlerische Gruppen ihre Proben und künstlerischen Experimente auf dem Gelände des Hauses durchführen.",
    laurindaCenterHours: "Von Dienstag bis Freitag, von 10 bis 19 Uhr; Samstag und Sonntag von 12 bis 19 Uhr.",
    laurindaCenterTelephone: "+55 (21) 2215-0618",
    chácaraDoCéuTitle: "Museu Chácara do Céu",
    chácaraDoCéuDescription: "Nach dem Parque das Ruínas können Sie das Museu Chácara do Céu besuchen, das ganz in der Nähe liegt. Das Museum befindet sich in einem schönen großen Gebäude mit einer einzigartigen Architektur, und in diesem Museum befindet sich die Privatsammlung eines Unternehmers, der früher an diesem Ort lebte. Der Ort bietet auch einen Panoramablick auf die Guanabara-Bucht. Das Gebäude hat drei Etagen und Kunstwerke aus verschiedenen Epochen und Herkunftsländern. Im Museum sind die Ausstellungen dauerhaft, obwohl einige temporär sind. Der Raum widmet eine Etage der europäischen Kunst und der orientalischen Kunst. Die Sammlung des Museums umfasst Ausstellungen von brasilianischen Künstlern wie Di Cavalcanti und Portinari; europäischen Künstlern wie Matisse und Modigliani sowie Miró und Ausstellungen von Reisenden, die als Brasiliana bekannt sind, mit alten Karten und Illustrationen von Künstlern wie Jean-Baptiste Debret, Chamberlain und Rugendas.",
    chácaraDoCéuHours: "Von Mittwoch bis Montag, von 12 bis 17 Uhr.",
    chácaraDoCéuTickets: "R$6,00 und es gibt ermäßigten Eintritt.",
    chácaraDoCéuFreeAdmission: "Freier Eintritt am Mittwoch",
    largoDoGuimaraesTitle: "Largo do Guimarães",
    largoDoGuimaraesDescription: "Wenn Sie nach all dem Gehen, Hinauf- und Hinuntersteigen hungrig werden, empfehle ich Ihnen, zum Largo do Guimarães zu gehen. Beim Spazierengehen können Sie die künstlerische Bewegung genießen, die immer stattfindet, und vielleicht treffen Sie jemanden, der auf der Straße Kunst macht. Nutzen Sie die Gelegenheit, die Studios zu besuchen.",
    gastronomicPointTitle: "Gastronomischer Punkt",
    gastronomicPointDescription: "Largo do Guimarães ist der gastronomische Punkt. Jeder weiß das, also wenn Sie sich dort verlaufen, es gibt viele Bars und Restaurants, ein bohemisches Zentrum im Viertel, ein sehr cooler Ort, um einen späten Nachmittag zu verbringen und die Atmosphäre von Santa Teresa zu genießen."
};

export default de;
