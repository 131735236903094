// src/screens/DescriptionScreen.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../utils/TranslationProvider';
import { fetchConvertedPrices } from '../utils/currencyConverter'; // Import the function
import guesthouseImage from '../assets/images/guesthouseinrio.jpg'; // Import the image
import './DescriptionScreen.css'; // Import the CSS

const DescriptionScreen = () => {
    const { t } = useTranslation(); // Use translation hook
    const [convertedPrices, setConvertedPrices] = useState({
        EUR: 0,
        GBP: 0,
        USD: 0,
        CHF: 0,
    });

    useEffect(() => {
        const targetCurrencies = ['EUR', 'GBP', 'USD', 'CHF'];

        fetchConvertedPrices(2400000, 'BRL', targetCurrencies)
            .then(setConvertedPrices)
            .catch(console.error);
    }, []); // Empty dependency array to run only once on mount

    const formatCurrency = (amount, currency) => {
        return new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: currency,
        }).format(amount);
    };

    return (
        <div className="description-screen" style={{ backgroundImage: `url(${guesthouseImage})` }}>
            <div className="content-container">
                <h1>{t('title')}</h1>
                <p>{t('description')}</p>
                <h2>{t('featuresTitle')}</h2>
                <ul>
                    {t('features', { returnObjects: true }).map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
                <h3>{t('price')}</h3>
                <div className="converted-prices">
                    <h4>{t('pricesInOtherCurrencies')}</h4>
                    <p>{formatCurrency(convertedPrices.EUR, 'EUR')}</p>
                    <p>{formatCurrency(convertedPrices.GBP, 'GBP')}</p>
                    <p>{formatCurrency(convertedPrices.USD, 'USD')}</p>
                    <p>{formatCurrency(convertedPrices.CHF, 'CHF')}</p>
                </div>
            </div>
        </div>
    );
};

export default DescriptionScreen;
