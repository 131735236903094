import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from '../utils/TranslationProvider'; // Import the custom hook
import guesthouseImage from '../assets/images/guesthouseinrio.jpg';
import './ContactScreen.css';

// Ensure the reCAPTCHA global function is defined
window.handleRecaptcha = function (response) {
  window.recaptchaToken = response;
};

const ContactScreen = () => {
  const { t } = useTranslation(); // Use the translation hook

  const [sending, setSending] = useState(false);

  useEffect(() => {
    // Reset the reCAPTCHA token on component mount or update
    window.recaptchaToken = '';

    // Ensure the reCAPTCHA script is loaded and ready
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        // reCAPTCHA script has been loaded
        console.log('reCAPTCHA script loaded');
      };

      script.onerror = () => {
        console.error('Failed to load reCAPTCHA script');
      };
    };

    loadRecaptchaScript();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = window.recaptchaToken;

    if (!token) {
      alert(t('recaptchaAlert')); // Use translation for reCAPTCHA alert
      return;
    }

    try {
      setSending(true);

      const form = e.target;

      // Append reCAPTCHA token to the form data
      const formData = new FormData(form);
      formData.append('g-recaptcha-response', token);

      const response = await emailjs.sendForm(
        'service_x219qhn',
        'template_miqk04o',
        form,
        'E4GdW3xfY6qPbi9jk'
      );

      console.log('EmailJS response:', response);
      alert(t('successMessage')); // Use translation for success message
      form.reset();
      window.recaptchaToken = ''; // Clear global token
    } catch (error) {
      console.error('EmailJS error:', error);
      alert(t('errorMessage')); // Use translation for error message
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="contact-screen" style={{ backgroundImage: `url(${guesthouseImage})` }}>
      <div className="content-container">
        <h1>{t('contactTitle')}</h1>
        <p>{t('contactMessage')}</p>
        <form id="contact-form" onSubmit={handleSubmit}>
          <input type="text" name="user_name" placeholder={t('namePlaceholder')} required />
          <input type="email" name="user_email" placeholder={t('emailPlaceholder')} required />
          <textarea name="message" placeholder={t('messagePlaceholder')} required />
          <div
            className="g-recaptcha"
            data-sitekey="6LcnXAYqAAAAAMXUxTWK6ad-PBNCMlSvhoEfe0h0"
            data-callback="handleRecaptcha"
            data-size="normal"
          ></div>
          <button type="submit" disabled={sending}>
            {t('sendButton')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactScreen;
