// src/utils/currencyConverter.js
export const fetchConvertedPrices = async (amount, baseCurrency, targetCurrencies) => {
    const apiKey = 'fca_live_DNoyQNyFLZDI8DX9HDkiAea8HzESV2BQydmXMiHo';
    const url = `https://api.freecurrencyapi.com/v1/latest?apikey=${apiKey}&base_currency=${baseCurrency}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (!data.data) {
            throw new Error('Failed to fetch exchange rates');
        }

        const rates = data.data;
        const convertedPrices = {};

        targetCurrencies.forEach(currency => {
            convertedPrices[currency] = (amount * rates[currency]).toFixed(2);
        });

        return convertedPrices;
    } catch (error) {
        console.error('Error fetching exchange rates:', error);
        throw error;
    }
};
