import React from 'react';
import { useTranslation } from '../utils/TranslationProvider';
import './NeighborhoodScreen.css';

const NeighborhoodScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="neighborhood-screen">
      <div className="overlay"></div>
      <div className="content-container">
        <h1>{t('neighborhoodTitle')}</h1>
        <p>{t('neighborhoodDescription')}</p>

        <section>
          <h2>{t('overviewTitle')}</h2>
          <p>{t('overviewDescription')}</p>
        </section>

        <section>
          <h2>{t('highlightsTitle')}</h2>
          <div className="highlights">
            <h3>{t('historyTitle')}</h3>
            <p>{t('historyDescription')}</p>

            <h3>{t('cultureTitle')}</h3>
            <p>{t('cultureDescription')}</p>

            <h3>{t('landmarksTitle')}</h3>
            <p>{t('landmarksDescription')}</p>
          </div>
        </section>

        <section>
          <h2>{t('culturalPlacesTitle')}</h2>
          <div className="cultural-places">
            <div className="place">
              <h3>{t('sambaGuimaraesTitle')}</h3>
              <p>{t('sambaGuimaraesDescription')}</p>
              <p><strong>{t('address')}:</strong> Rua Almirante Alexandrino, 501 – Santa Teresa, Rio de Janeiro</p>
              <p><strong>{t('openingHours')}:</strong> {t('sambaGuimaraesHours')}</p>
            </div>

            <div className="place">
              <h3>{t('cineCasalTitle')}</h3>
              <p>{t('cineCasalDescription')}</p>
              <p><strong>{t('address')}:</strong> Rua Paschoal Carlos Magno, 136 – Santa Teresa, Rio de Janeiro</p>
              <p><strong>{t('telephone')}:</strong> +55 (21) 2222-0203</p>
            </div>

            <div className="place">
              <h3>{t('tramMuseumTitle')}</h3>
              <p>{t('tramMuseumDescription')}</p>
              <p><strong>{t('address')}:</strong> Rua Carlos Brant, 14 – Santa Teresa, Rio de Janeiro</p>
              <p><strong>{t('openingHours')}:</strong> {t('tramMuseumHours')}</p>
            </div>

            <div className="place">
              <h3>{t('ruinsParkTitle')}</h3>
              <p>{t('ruinsParkDescription')}</p>
              <p><strong>{t('address')}:</strong> Rua Murtinho Nobre, 169 – Santa Teresa</p>
              <p><strong>{t('openingHours')}:</strong> {t('ruinsParkHours')}</p>
            </div>
          </div>
        </section>

        <section>
          <h2>{t('gastronomicPointsTitle')}</h2>
          <div className="gastronomic-points">
            <div className="restaurant">
              <h3>{t('healthyOptionsTitle')}</h3>
              <div className="restaurant-info">
                <h4>{t('malocaTitle')}</h4>
                <p>{t('malocaDescription')}</p>
                <p><strong>{t('address')}:</strong> Rua Paschoal Carlos Magno, 103 loja B – Santa Teresa, Rio de Janeiro</p>
              </div>

              <div className="restaurant-info">
                <h4>{t('cantinhoTitle')}</h4>
                <p>{t('cantinhoDescription')}</p>
                <p><strong>{t('address')}:</strong> Rua Aarão Reis, 20 – Santa Teresa, Rio de Janeiro</p>
              </div>
            </div>

            <div className="restaurant">
              <h3>{t('refinedOptionsTitle')}</h3>
              <div className="restaurant-info">
                <h4>{t('explorerTitle')}</h4>
                <p>{t('explorerDescription')}</p>
                <p><strong>{t('address')}:</strong> Rua Almirante Alexandrino, 399 – Santa Teresa, Rio de Janeiro</p>
              </div>

              <div className="restaurant-info">
                <h4>{t('aprazivelTitle')}</h4>
                <p>{t('aprazivelDescription')}</p>
                <p><strong>{t('address')}:</strong> Rua Aprazível, 62 – Santa Teresa, Rio de Janeiro</p>
              </div>
            </div>

            <div className="restaurant">
              <h3>{t('traditionalRestaurantsTitle')}</h3>
              <div className="restaurant-info">
                <h4>{t('cafeAltoTitle')}</h4>
                <p>{t('cafeAltoDescription')}</p>
                <p><strong>{t('address')}:</strong> Rua Paschoal Carlos Magno, 143 – Santa Teresa, Rio de Janeiro</p>
              </div>

              <div className="restaurant-info">
                <h4>{t('barMineiroTitle')}</h4>
                <p>{t('barMineiroDescription')}</p>
                <p><strong>{t('address')}:</strong> Rua Paschoal Carlos Magno, 99 – Santa Teresa, Rio de Janeiro</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2>{t('attractionsTitle')}</h2>
          <div className="attractions">
            <h3>{t('santaTeresaTramTitle')}</h3>
            <p>{t('santaTeresaTramDescription')}</p>

            <h3>{t('tijucaNationalForestTitle')}</h3>
            <p>{t('tijucaNationalForestDescription')}</p>
          </div>
        </section>

        <section>
          <h2>{t('mapTitle')}</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.471046881388!2d-43.18685182368893!3d-22.92363908017382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x997f84bafc78df%3A0x882d64d238d2cf8b!2sSanta%20Teresa%2C%20Rio%20de%20Janeiro%20-%20RJ!5e0!3m2!1sen!2sbr!4v1698767500990!5m2!1sen!2sbr"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Google Maps location of Santa Teresa, Rio de Janeiro"
          ></iframe>
        </section>
      </div>
    </div>
  );
};

export default NeighborhoodScreen;
